export function populateContactForm(contactFormEl) {
  console.log("populateContactForm", contactFormEl)
  let currentURL = new URL(window.location.href)

  if (currentURL.hash.length > 0) {
    let hash = currentURL.hash
    let hashQuery = hash.replace("#!", "?")
    let params = new URLSearchParams(hashQuery)

    const fields = ["name", "email", "message"]

    for (let field of fields) {
      if (params.has(field)) {
        console.log(`input[name=${CSS.escape(field)}]`)
        let input = contactFormEl.querySelector(`[name=${CSS.escape(field)}]`)
        input.value = params.get(field)
      }
    }
  }
}
